<template>
  <div>
    <table v-if="clinics && clinics.length">
      <tbody>
        <tr>
          <!-- Первый столбик -->
          <td class="column">
            <div
              v-for="clinic in firstColumn"
              :key="clinic.id"
              class="border-bottom p-2"
            >
              {{ clinic.name }} - {{ clinic.cityName }}
            </div>
          </td>
          <!-- Второй столбик -->
          <td class="column">
            <div
              v-for="clinic in secondColumn"
              :key="clinic.id"
              class="border-bottom p-2"
            >
              {{ clinic.name }} - {{ clinic.cityName }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <p v-else>
      Нет данных для отображения.
    </p>
  </div>
</template>

<script>
export default {
  name: 'ClinicsTable',
  props: {
    clinics: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    firstColumn() {
      return this.clinics ? this.clinics.slice(0, Math.ceil(this.clinics.length / 2)) : [];
    },
    secondColumn() {
      return this.clinics ? this.clinics.slice(Math.ceil(this.clinics.length / 2)) : [];
    },
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

td {
  vertical-align: top;
  width: 50%;
  padding: 8px;
  border: 1px solid #ddd;
}

</style>
