<template>
  <div>
    <div
      v-for="(entity, index) in entities"
      :key="entity.id"
      class="mt-3"
    >
      <legal-entity-info :entity="entity" />
      <!-- открытие списка -->
      <transition name="fade">
        <div
          v-if="!entity.isInfoShow"
          style="cursor: pointer; height: 20px"
          class="bg-primary rounded-bottom d-flex justify-content-center p-1"
          @click="toggleInfo(index)"
        >
          <b-icon
            icon="caret-down-fill"
            variant="light"
            :rotate="entity.isInfoShow ? 180 : 0"
          />
        </div>
      </transition>

      <transition name="fade">
        <!-- Информация -->
        <div
          v-if="entity.isInfoShow"
          class="bg-white border w-100"
        >
          <div class="p-4">
            <p>ФИО руководителя - {{ entity.leaderFio }} </p>
            <legal-entities-clinics-table :clinics="entity.clinics" />
          </div>

          <!-- закрытие списка -->
          <div
            v-if="entity.isInfoShow"
            style="cursor: pointer; height: 20px"
            class="bg-primary rounded-bottom d-flex justify-content-center p-1"
            @click="toggleInfo(index)"
          >
            <b-icon
              icon="caret-down-fill"
              variant="light"
              :rotate="entity.isInfoShow ? 180 : 0"
            />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import LegalEntityInfo from '@/components/LegalEntities/LegalEntityInfo';
import LegalEntitiesClinicsTable from '@/components/LegalEntities/LegalEntitiesClinicsTable';

export default {
  name: 'LegalEntitiesItems',
  components: {
    LegalEntityInfo,
    LegalEntitiesClinicsTable,
  },
  data() {
    return {
      entities: [],
    };
  },
  computed: {
    legalEntities() {
      return this.$store.state.LegalEntities.legalEntities;
    },
  },
  watch: {
    legalEntities: {
      handler(newEntities) {
        if (newEntities && newEntities.length) {
          this.entities = this.initializeEntities(newEntities);
        } else {
          this.entities = [];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    initializeEntities(entities = []) {
      return entities.map(((entity) => ({
        ...entity,
        isInfoShow: false,
      })));
    },
    toggleInfo(index) {
      this.entities[index].isInfoShow = !this.entities[index].isInfoShow;
    },
  },
};

</script>

<style scoped>
.fade-enter-active {
  transition: all .5s ease;
}
.fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
 }

.fade-enter,
.fade-leave-active {
  transform: translateX(15px);
  opacity: 0;
}
</style>
